import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../logo.png';
import ContactForm from './ContactForm';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleFormSubmit = () => {
    setIsDrawerOpen(false);
  };

  const handleMenuOpen = (event) => {
    // Ensure event.currentTarget is being used to set the anchorEl
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(135deg, #006a71, #1f2f86)',
          padding: '5px 10px',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'auto',
            ...(isMobile && {
              justifyContent: 'space-between',
              padding: '0 16px',
            }),
          }}
        >
          {isMobile ? (
            <>
              {/* Logo on the Left */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Lowen Behold Cleaning Logo"
                    style={{ width: '80px', height: '80px' }}
                  />
                </Link>
              </Box>

              {/* Menu Icon on the Right */}
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{
                  color: '#ffffff',
                  fontSize: '2.5rem',
                }}
              >
                <MenuIcon sx={{ fontSize: 'inherit' }} />
              </IconButton>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              {/* Buttons */}
              <Button component={Link} to="/" variant="text" sx={{ color: location.pathname === '/' ? '#ffffff' : '#9fbcdf', fontWeight: location.pathname === '/' ? 'bold' : 'normal', borderBottom: location.pathname === '/' ? '2px solid #ffffff' : 'none', fontSize: '1.2rem', textTransform: 'none', padding: '4px 8px', '&:hover': { color: '#ffffff', borderBottom: '2px solid #ffffff', }, }}>Home</Button>
              <Button component={Link} to="/services" variant="text" sx={{ color: location.pathname === '/services' ? '#ffffff' : '#9fbcdf', fontWeight: location.pathname === '/services' ? 'bold' : 'normal', borderBottom: location.pathname === '/services' ? '2px solid #ffffff' : 'none', fontSize: '1.2rem', textTransform: 'none', padding: '4px 8px', '&:hover': { color: '#ffffff', borderBottom: '2px solid #ffffff', }, }}>Services</Button>

              {/* Logo Centered */}
              <Box sx={{ textAlign: 'center' }}>
                <img
                  src={logo}
                  alt="Lowen Behold Cleaning Logo"
                  style={{ width: '120px', height: '120px' }}
                />
              </Box>

              <Button component={Link} to="/about" variant="text" sx={{ color: location.pathname === '/about' ? '#ffffff' : '#9fbcdf', fontWeight: location.pathname === '/about' ? 'bold' : 'normal', borderBottom: location.pathname === '/about' ? '2px solid #ffffff' : 'none', fontSize: '1.2rem', textTransform: 'none', padding: '4px 8px', '&:hover': { color: '#ffffff', borderBottom: '2px solid #ffffff', }, }}>About</Button>
              <Button variant="text" sx={{ color: location.pathname === '/contact' ? '#ffffff' : '#9fbcdf', fontWeight: location.pathname === '/contact' ? 'bold' : 'normal', borderBottom: location.pathname === '/contact' ? '2px solid #ffffff' : 'none', fontSize: '1.2rem', textTransform: 'none', padding: '4px 8px', '&:hover': { color: '#ffffff', borderBottom: '2px solid #ffffff', }, }} onClick={toggleDrawer(true)}>Contact Us</Button>
            </Box>
          )}
        </Toolbar>

        {/* Menu Component */}
        <Menu
          anchorEl={menuAnchorEl} // Ensure anchorEl is valid
          open={Boolean(menuAnchorEl)} // Only open the menu if anchorEl is set
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: '#1f2f86',
              color: '#ffffff',
            },
          }}
        >
          <MenuItem component={Link} to="/" onClick={handleMenuClose}>Home</MenuItem>
          <MenuItem component={Link} to="/services" onClick={handleMenuClose}>Services</MenuItem>
          <MenuItem component={Link} to="/about" onClick={handleMenuClose}>About</MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); toggleDrawer(true)(); }}>Contact Us</MenuItem>
        </Menu>

        {/* Drawer */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            '& .MuiDrawer-paper': {
              width: { xs: '90%', sm: '400px' },
              height: { xs: '900%', sm: '100%' },
              padding: { xs: '15px', sm: '20px' },
              background: 'white',
              color: '#004466',
            },
          }}
        >
          <Box sx={{ width: '100%', textAlign: 'center' }} role="presentation">
            <IconButton
              onClick={toggleDrawer(false)}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: '#004466',
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Logo */}
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <img
                src={logo}
                alt="Lowen Behold Cleaning Logo"
                style={{ width: '120px', height: '120px' }}
              />
            </Box>
            <ContactForm onClose={handleFormSubmit} />
          </Box>
        </Drawer>
      </AppBar>
    </>
  );
};

export default Header;
