import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import cleaningImage from '../womancleaning.png';
import cleanRoomImage from '../cleanroom.png';

const Home = () => {
  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        color: '#333', // Darker text for readability
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: '10px',
        }}
      >
        {/* Text on the left */}
        <Box sx={{ flex: 1, paddingRight: { xs: '0', sm: '20px' }, marginBottom: { xs: '10px', sm: '0' } }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#004466', fontSize: { xs: '1.5rem', sm: '2rem' } }}
          >
            Lowen Behold Cleaning Services
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#006a71' }}>
            We Clean Exceptionally...<strong>So <i>You</i> Don't Have To!</strong>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '10px', color: '#333' }}>
            We know how hard it can be to meet all the demands of life & have to clean the house or office too...
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#006a71' }}>
            That's where we come in!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px', color: '#333' }}>
            Lowen Behold Cleaning Services will take the burden off your busy schedule, so you have time to do the
            things you love! As experts in the professional cleaning industry, we specialize in all types of cleaning
            for both residential and commercial properties. From the kitchen to the bathroom, your office to showroom
            and every room in-between, we've got you covered!
          </Typography>
        </Box>

        {/* Image on the right */}
        <Box sx={{ flexShrink: 0, textAlign: { xs: 'center', sm: 'center' } }}>
          <img
            src={cleaningImage}
            alt="Professional residential cleaning service in Eugene"
            style={{ width: '100%', maxWidth: '300px', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
          />
        </Box>
      </Box>

      {/* Section for benefits and clean room image */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          marginBottom: '25px',
          color: '#004466',
          textAlign: 'center',
        }}
      >
        No job is too small or too large for Lowen Behold Cleaning Services!
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: '10px', sm: '20px', md: '40px' }, // Add spacing between items
        }}
      >
        {/* Clean Room Image */}
        <Box
          sx={{
            flexShrink: 0,
            textAlign: 'center',
            maxWidth: { xs: '100%', sm: '300px', md: '250px' }, // Adjust size for tablets
            margin: { xs: '10px 0', sm: '0' }, // Responsive margins
          }}
        >
          <img
            src={cleanRoomImage}
            alt="Affordable commercial cleaning services in Springfield"
            style={{ width: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
          />
        </Box>

        {/* Benefits List */}
        <Box
          sx={{
            flex: 1,
            textAlign: { xs: 'left', sm: 'left' },
            maxWidth: { xs: '100%', sm: '600px' },
            marginLeft: { sm: '0', md: '20px' }, // Add spacing on medium screens
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#004466' }}>
            Benefits of Our Cleaning Services:
          </Typography>
          <List sx={{ textAlign: 'left', marginBottom: '20px' }}>
            {[
              'Time saved: Focus on what really matters while we handle the cleaning.',
              'Money saved: Avoid costly cleaning supplies and equipment.',
              'Professional results: Our staff ensures thorough, top-quality cleaning.',
              'Stress relief: Enjoy a clean, organized space without the hassle.',
              'Flexible scheduling: We work around your schedule!',
              'Customizable: Tailor our cleaning services to meet your unique needs.',
              'Detail-Oriented: We pay attention to even the smallest details!',
            ].map((benefit, index) => (
              <ListItem key={index} disablePadding>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" sx={{ color: '#0096c7' }} />
                </ListItemIcon>
                <ListItemText primary={benefit} sx={{ color: '#333' }} />
              </ListItem>
            ))}
          </List>
          <Typography variant="h5" sx={{ color: '#004466', fontWeight: 'bold', textAlign: { xs: 'center', sm: 'left' } }}>
            Contact us today for a free estimate!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
